<template>
  <div class="mb-100">
    <div class="divider-full"></div>
    <div class="container-fluid section-head">
      <div class="container-standar container-res-1440">
        <span class="pt-6 pb-6 text-white font-weight-6 font-24 d-inline-block">Pengaturan</span>
      </div>
    </div>
    <div class="cotainer-standar container-res-1440">
      <div class="container-standar mt-10 mb-10">
        <!-- <div class="row"> -->
            <b-card no-body>
                <b-tabs pills card vertical>
                  <b-tab active>
                      <template v-slot:title>
                          <span
                              class="width-100 pt-2 pb-2 font-14 font-weight-7 text-primary"
                              >Edit Profile</span>
                      </template>
                      <b-card-text>
                          <div class="card shadow text-left pad-10">
                              <span
                              class="d-inline-block bor-bot-1 width-100 pt-2 pb-2 font-18 font-weight-7 text-primary"
                              >Biodata & Informasi Alamat</span>
                              <div class="col-lg-12">
                                  <div class="form-group row mt-3">
                                      <div class="form-group col-lg-4">
                                          <label class="text-primary font-12 font-weight-6" for="nama">Nama</label>
                                          <input type="text" class="form-control" id="nama"
                                              placeholder="Nama Lengkap"
                                              v-model="fullName"
                                              name="name">
                                      </div>
                                      <div class="form-group col-lg-4">
                                          <label class="text-primary font-12 font-weight-6" for="phone">Phone</label>
                                          <input type="text" class="form-control" id="phone"
                                              placeholder="No. Telp"
                                              v-model="userPhone" name="phone">
                                      </div>
                                      <div class="form-group col-lg-4">
                                          <label class="text-primary font-12 font-weight-6" for="email">Email</label>
                                          <input type="text" class="form-control" id="email"
                                              placeholder="Email"
                                              v-model="userEmail" name="email">
                                      </div>

                                      <div class="form-group col-lg-6">
                                          <label class="text-primary font-12 font-weight-6">Provinsi</label>
                                          <select class="form-control" name="province_id" v-model="selectedProp" @change="changeProvinsi">
                                              <option value="null">Pilih Provinsi</option>
                                              <option :key="i" v-for="(items, i) in provinceList" v-bind:value="items.id">{{items.name}}</option>
                                          </select>
                                      </div>
                                      <div class="form-group col-lg-6">
                                          <label class="text-primary font-12 font-weight-6" for="phone">Kota</label>
                                          <select class="form-control" name="city_id" v-model="selectedCity" @change="changeCity">
                                              <option value="null">Pilih Kota</option>
                                              <option :key="i" v-for="(items, i) in cityList" :value="items.id">{{items.type}} {{items.name}}</option>
                                          </select>
                                      </div>
                                      <div class="form-group col-lg-6">
                                          <label class="text-primary font-12 font-weight-6" for="phone">Kecamatan</label>
                                          <select class="form-control" name="subdistrict_id" v-model="selectedSubdisctirct">
                                              <option value="null">Pilih Kecamatan</option>
                                              <option :key="i" v-for="(items, i) in subdistrictList" v-bind:value="items.id">{{items.name}}</option>
                                          </select>
                                      </div>
                                      <div class="form-group col-lg-6">
                                          <label class="text-primary font-12 font-weight-6" for="zipcode">Kode Pos</label>
                                          <input type="text" class="form-control" v-model="zipcode" id="zipcode" name="zipcode">
                                      </div>
                                      <div class="form-group col-lg-12">
                                          <label class="text-primary font-12 font-weight-6" for="address">Alamat</label>
                                          <textarea class="form-control" v-model="address" id="address" name="address" cols="30" rows="5"></textarea>
                                      </div>
                                      <div class="form-group col-lg-12 pt-4 pb-4 pad-10 d-inline-block">
                                          <span
                                              @click.prevent="updateProfile()"
                                              class="d-block w-80 bg-warning text-center pad-15 border-radius-5 cursor-pointer text-white"
                                          >Submit</span>
                                      </div>
                                  </div>
                              </div>
                          </div> 
                      </b-card-text>
                  </b-tab>
                  <b-tab>
                      <template v-slot:title>
                          <span
                              class="width-100 pt-2 pb-2 font-14 font-weight-7 text-primary"
                              >Ubah Password</span>
                      </template>
                      <b-card-text class="width-100">
                          <div class="card shadow text-left pad-10">
                              <span
                              class="d-inline-block bor-bot-1 width-100 pt-2 pb-2 font-18 font-weight-7 text-primary"
                              >Ubah Password</span>
                              <div class="col-lg-12">
                                  <div class="form-group row mt-3">
                                      <div class="form-group col-lg-6">
                                          <label class="text-primary font-12 font-weight-6" for="nama">Password Lama</label>
                                          <input type="password" class="form-control" id="nama"
                                              placeholder="Masukan password lama"
                                              v-model="passwordOld"
                                              name="name">
                                      </div>
                                      <div class="form-group col-lg-6">
                                          <label class="text-primary font-12 font-weight-6" for="phone">Password Baru</label>
                                          <input type="password" class="form-control" id="phone"
                                              placeholder="Konfirmasi password"
                                              v-model="passwordNew" name="password_old">
                                      </div>
                                      <div class="form-group col-lg-12 pt-4 pb-4 pad-10 d-inline-block">
                                          <span
                                              @click.prevent="updatePassword()"
                                              class="d-block w-80 bg-warning text-center pad-15 border-radius-5 cursor-pointer text-white"
                                          >Submit</span>
                                      </div>
                                  </div>
                              </div>
                          </div> 
                      </b-card-text>
                  </b-tab>
                </b-tabs>
            </b-card>
        <!-- </div> -->
      </div>
    </div>

    <b-modal id="modal-Success" size="lg" hide-footer no-close-on-backdrop centered title>
      <div class="col-md-12 pb-6 text-center">
        <div class="col-lg-12 mb-10">
          <div class="card shadow text-left">
            <iframe id="frame" v-bind:src="urlDetail" width="100%" height="600"></iframe>
          </div>
        </div>
      </div>
    </b-modal>

  </div>
</template>

<script>
import "flatpickr/dist/flatpickr.css";
export default {
  components: {
    // flatPickr
  },
  data() {
    return {
      LoginCheck: false,
      fullName:'',
      userPhone:'',
      userEmail:'',
    //   nama:'',
      provinceList:[],
      cityList:[],
      subdistrictList:[],
      selectedProp: '',
      selectedCity: '',
      selectedSubdisctirct: '',
      zipcode: '',
      address:'',
      passwordOld: '',
      passwordNew:'',
      userData: this.$store.state.userdata,
      date: new Date(),
      tipeTrans:null,
      urlDetail:'',
      sortOptions: [
          { "id": null, text: 'Please select an option' },
          { "id": 'a', text: 'This is First option' },
          { "id": 'b', text: 'Selected Option' },
          { "id": '3PO', text: 'This is an option with object value' },
          { "id": 'd', text: 'This one is disabled', disabled: true }
        ],
      sortTipe:[
        { "id": 0, text: 'Umroh' },
        { "id": 1, text: 'Haji'},
        { "id": 2, text: 'Flight' },
        { "id": 3, text: 'Hotel' },
        { "id": 4, text: 'Tour'},
        { "id": 5, text: 'Visa'},
      ],
      config: {
        mode: "single",
        minDate: "today",
        dateFormat: "D d-M-Y"
      },
    };
  },
  watch: {
  },
  mounted() {
    this.$store.commit("SET_BG", false);
    this.getProvince();
    this.changeProvinsi();
    this.changeCity();
    this.getUserData();
  },

  methods: { 
    getProvince(){
        this.axios.get("https://api.realtravel.co.id/common/province", {
          headers: new Headers({
            accept: "application/json",
            "Content-Type": "multipart/form-data, text/plain"
          })
        }).then(response => {
          let res = response.data;
          var obj = res.response.data;
          
          for (let i = 0; i < obj.length; i++) {
            this.provinceList.push(obj[i]);
          }
        })
        .catch(error => {
         error
        });
    },
    changeProvinsi() {
        setTimeout(() => {
            this.axios.get("https://api.realtravel.co.id/common/city?province_id="+this.selectedProp, {
                headers: new Headers({
                    accept: "application/json",
                    "Content-Type": "multipart/form-data, text/plain"
                })
            }).then(response => {
                let res = response.data;
                var obj = res.response.data;
                
                this.cityList = []
                for (let i = 0; i < obj.length; i++) {
                    this.cityList.push(obj[i]);
                }
            })
            .catch(error => {
                error
            });
        }, 500);
    },
    changeCity() {
        setTimeout(() => {
            this.axios.get("https://api.realtravel.co.id/common/subdistrict?city_id="+this.selectedCity, {
                headers: new Headers({
                    accept: "application/json",
                    "Content-Type": "multipart/form-data, text/plain"
                })
            }).then(response => {
                let res = response.data;
                var obj = res.response.data;
                
                this.subdistrictList = []
                for (let i = 0; i < obj.length; i++) {
                    this.subdistrictList.push(obj[i]);
                }
            })
            .catch(error => {
                error
            });
        }, 500);
    },
    getUserData(){
      if (this.$store.state.userdata != null) {
        this.fullName = this.$store.state.userdata.name;
        this.userPhone = this.$store.state.userdata.phone;
        this.userEmail = this.$store.state.userdata.email;
        this.zipcode = this.$store.state.userdata.zipcode;
        this.address = this.$store.state.userdata.address;
        this.selectedProp = this.$store.state.userdata.province_id;
        this.selectedCity = this.$store.state.userdata.city_id;
        this.selectedSubdisctirct = this.$store.state.userdata.subdistrict_id;
      }
    }, 
    updateProfile(){
        let param = new URLSearchParams();
        param.append("name", this.fullName);
        param.append("email", this.userEmail);
        param.append("phone", this.userPhone);
        param.append("address", this.address);
        param.append("province_id", this.selectedProp);
        param.append("city_id", this.selectedCity);
        param.append("subdistrict_id", this.selectedSubdisctirct);
        param.append("zipcode", this.zipcode);
        param.append("latitude", this.userData.latitude);
        param.append("longitude", this.userData.longitude);

        this.axios.put("https://api.realtravel.co.id/user/update", param, {
          headers: {
            "token": this.userData.token,
            "Content-Type": "application/x-www-form-urlencoded"
          }
        }).then(response => {
          let res = response.data
          var obj = res;
          if (obj.code == 200) {
              this.$swal("", '<span class="font-weight-5 font-14 text-success">'+obj.message+'</span>', "success");
          } else {
              this.$swal("", '<span class="font-weight-5 font-14 text-warning">'+obj.message+'</span>', "warning");
          }
        })
        .catch(error => {
         error
        });
    }, 
    updatePassword(){
        let param = new URLSearchParams();
        param.append("password", this.passwordOld);
        param.append("password_confirm", this.passwordNew);

        this.axios.put("https://api.realtravel.co.id/user/update_password", param, {
          headers: {
            "token": this.userData.token,
            "Content-Type": "application/x-www-form-urlencoded"
          }
        }).then(response => {
          let res = response.data
          var obj = res;
          if (obj.code == 200) {
              this.$swal("", '<span class="font-weight-5 font-14 text-success">'+obj.message+'</span>', "success");
          } else {
              this.$swal("", '<span class="font-weight-5 font-14 text-warning">Konfirmasi password tidak sama.</span>', "warning");
          }
        })
        .catch(error => {
         error
        });
    },
    OpenPayment(val){
      this.$bvModal.show("modal-Success");
      this.urlDetail = val;
    },
  }
};
</script>